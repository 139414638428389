import React, { useRef } from "react"
import styled from "styled-components"
import cn from "classnames"
import { useAccessibility } from "~components/Accessibility/AccessibilityProvider"
import { useSlider, useSliderThumb } from "@react-aria/slider"
import { useSliderState } from "@react-stately/slider"
import { useFocusRing } from "@react-aria/focus"
import { VisuallyHidden } from "@react-aria/visually-hidden"
import { mergeProps } from "@react-aria/utils"
import { useNumberFormatter } from "@react-aria/i18n"

const ThumbWrapper = styled.div`
  width: 11px;
  height: 11px;
  top: 14px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.darkishBlue};
  .accessible & {
    background-color: ${({ theme }) => theme.darkBlueAccessible};
  }
  .white & {
    background-color: ${({ theme }) => theme.white};
  }
  ${({ theme, isFocusVisible }) =>
    isFocusVisible &&
    `
    &:before {
      position: absolute;
      content: "";
      top: -3px;
      left: -3px;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      border: solid 2px ${theme.darkishBlue};
    }
    .accessible &:before {
      border-color: ${theme.darkBlueAccessible};
    }
    .white &:before {
      border-color: ${theme.white};
    }
  `}
`

const VolumeSliderWrapper = styled.div`
  width: 50px;
  touch-action: none;
`

const BarWrapper = styled.div`
  height: 30px;
`

const Placeholder = styled.div`
  height: 3px;
  top: 13px;
  opacity: 0.3;
  background-color: ${({ theme }) => theme.darkishBlue};
  .accessible & {
    background-color: ${({ theme }) => theme.darkBlueAccessible};
  }
  .white & {
    background-color: ${({ theme }) => theme.white};
  }
`

const Bar = styled.div`
  height: 3px;
  top: 13px;
  background-color: ${({ theme }) => theme.darkishBlue};
  .accessible & {
    background-color: ${({ theme }) => theme.darkBlueAccessible};
  }
  .white & {
    background-color: ${({ theme }) => theme.white};
  }
`

const Thumb = ({ onBlur, ...props }) => {
  let { state, trackRef, index } = props
  let inputRef = useRef(null)
  let { thumbProps, inputProps } = useSliderThumb(
    {
      index,
      trackRef,
      inputRef,
    },
    state
  )

  let { focusProps, isFocusVisible } = useFocusRing()

  return (
    <ThumbWrapper {...thumbProps} isFocusVisible={isFocusVisible}>
      <VisuallyHidden>
        <input
          ref={inputRef}
          {...mergeProps(inputProps, focusProps, { onBlur })}
        />
      </VisuallyHidden>
    </ThumbWrapper>
  )
}

const PlayerVolumeSlider = ({ className, onBlur, color, ...props }) => {
  let trackRef = useRef(null)
  const {
    accessibility: { accessible },
  } = useAccessibility()
  let numberFormatter = useNumberFormatter(props.formatOptions)
  let state = useSliderState({ ...props, numberFormatter })
  let { groupProps, trackProps } = useSlider({ ...props }, state, trackRef)

  return (
    <VolumeSliderWrapper
      {...groupProps}
      className={cn(
        "relative",
        { accessible: accessible },
        { white: color === "white" },
        className
      )}
    >
      <BarWrapper {...trackProps} ref={trackRef} className="relative w-full">
        <Placeholder className="absolute w-full" />
        <Bar
          className="absolute"
          style={{
            width: `${state.getThumbPercent(0) * 100}%`,
          }}
        />
        <Thumb index={0} state={state} trackRef={trackRef} onBlur={onBlur} />
      </BarWrapper>
    </VolumeSliderWrapper>
  )
}

export default PlayerVolumeSlider
