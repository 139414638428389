import React, { useEffect, useState, useRef } from "react"
import cn from "classnames"
import styled from "styled-components"

const StyledMarqueeWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
`

const StyledMarqueeContent = styled.div`
  display: flex;
  position: relative;
`

const StyledText = styled.span`
  ${({ margin }) =>
    margin &&
    `
    margin-right: ${margin}px;
  `}
`

const Marquee = ({ className, text, margin, ...props }) => {
  const defaultDelay = 25

  const [translation, setTranslation] = useState(0)

  const [delay, setDelay] = useState(null)
  const wrapperRef = useRef(null)
  const textRef = useRef(null)
  const contentRef = useRef(null)

  const savedTranslateCallback = useRef(null)

  const canPlay = () => {
    return (
      textRef.current.getBoundingClientRect().width >
      wrapperRef.current.getBoundingClientRect().width
    )
  }

  useEffect(() => {
    let intervalId = null
    let currentWrapperRef = wrapperRef.current

    const handleMouseenter = () => {
      if (canPlay()) {
        setDelay(defaultDelay)
      }
    }

    const handleResize = () => {
      if (!canPlay() && defaultDelay) {
        setTranslation(0)
        setDelay(null)
      }
    }

    currentWrapperRef.addEventListener("mouseenter", handleMouseenter)
    window.addEventListener("resize", handleResize)
    if (delay) {
      const translate = () => {
        savedTranslateCallback.current()
      }
      intervalId = setInterval(translate, delay)
    }

    return () => {
      clearInterval(intervalId)
      currentWrapperRef.removeEventListener("mouseenter", handleMouseenter)
      window.removeEventListener("resize", handleResize)
    }
  }, [delay])

  useEffect(() => {
    savedTranslateCallback.current = () => {
      let newTranslation = translation - 1
      if (
        Math.abs(newTranslation) >
        textRef.current.getBoundingClientRect().width + margin
      ) {
        setTranslation(0)
        setDelay(null)
      } else {
        setTranslation(newTranslation)
      }
    }
  }, [translation, margin])

  const style = {
    transform: "translate3d(" + translation + "px, 0px, 0px)",
    willChange: "transform",
  }

  return (
    <StyledMarqueeWrapper
      ref={wrapperRef}
      className={cn(className, "marquee-wrapper")}
      {...props}
    >
      <StyledMarqueeContent
        ref={contentRef}
        className="marquee-content"
        style={style}
      >
        <StyledText
          ref={textRef}
          margin={margin}
          className="marquee-text"
          dangerouslySetInnerHTML={{ __html: text }}
        />
        {delay && (
          <StyledText
            margin={margin}
            className="marquee-text"
            dangerouslySetInnerHTML={{ __html: text }}
          />
        )}
      </StyledMarqueeContent>
    </StyledMarqueeWrapper>
  )
}

export default Marquee
