import React from "react"
import { SSRProvider } from "@react-aria/ssr"
import { OverlayProvider } from "@react-aria/overlays"
import { CookiesProvider } from "react-cookie"
import GlobalStyle from "~components/GlobalStyle"
import { AccessibilityProvider } from "~components/Accessibility/AccessibilityProvider"
import { DidomiBadge } from "~components/Didomi"
import { DidomiProvider } from "~components/Didomi/DidomiContext"
import MediaPlayerProvider from "~components/Player/MediaPlayerProvider"
import PodcastPlayer from "~components/PodcastPlayer/PodcastPlayer"
import { SearchIndexProvider } from "~components/SearchIndex"
import AppThemeProvider from "~theme"

const LayoutWrapper = ({ children, ...props }) => {
  return (
    <SSRProvider>
      <OverlayProvider>
        <CookiesProvider>
          <AccessibilityProvider>
            <DidomiProvider>
              <AppThemeProvider {...props}>
                <GlobalStyle />
                <SearchIndexProvider defaultIndex="site">
                  <MediaPlayerProvider>
                    {children}
                    <PodcastPlayer />
                    <DidomiBadge />
                  </MediaPlayerProvider>
                </SearchIndexProvider>
              </AppThemeProvider>
            </DidomiProvider>
          </AccessibilityProvider>
        </CookiesProvider>
      </OverlayProvider>
    </SSRProvider>
  )
}

export default LayoutWrapper
