import React, { useState, useRef, useEffect, useCallback } from "react"
import styled from "styled-components"
import { upMd } from "~utils/breakpoints"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container } from "~components/Container"
import Row from "~components/Row/Row"
import Col from "~components/Col/Col"
import { IconButton } from "~components/Button"
import Marquee from "~components/Marquee"
import { useMediaPlayer } from "~components/Player/MediaPlayerProvider"
import { audioTracking } from "~utils/tracking"
import {
  usePlayerVolume,
  usePlayerProgressBar,
  usePodcastPlayerShortcuts,
} from "~components/Player"
import { durationFormat } from "~components/Duration"
import Prev from "~images/inline/player-rev.svg"
import Next from "~images/inline/player-ff.svg"
import Play from "~images/inline/player-play.svg"
import Pause from "~images/inline/player-pause.svg"
import PrevWhite from "~images/inline/player-rev-white.svg"
import NextWhite from "~images/inline/player-ff-white.svg"
import PlayWhite from "~images/inline/player-play-white.svg"
import PauseWhite from "~images/inline/player-pause-white.svg"
import Close from "~images/inline/close.svg"
import CloseWhite from "~images/inline/close-white.svg"
import DangerWhite from "~images/inline/danger-white.svg"
import ChevronDownWhite from "~images/inline/chevron-down-white.svg"
import Dl from "~images/inline/player-dl.svg"
import DlWhite from "~images/inline/player-dl-white.svg"
import PlayingWhite from "~images/inline/icon-playing-white.svg"
import PlayingWhiteAnimated from "~images/inline/icon-playing-white-animated.svg"
import { useTheme } from "~hooks/theme"

const PodcastPlayerWidget = styled(IconButton)`
  width: 64px;
  height: 64px;
  position: fixed;
  z-index: 1980;
  bottom: 16px;
  right: 16px;
  border-radius: 50%;
  background: ${({ theme }) => theme.white};
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 30%);
`

const PlayerWrapper = styled.div`
  width: 100%;
  height: 120px;
`

const Controls = styled.div``

const VolumeWrapper = styled.div``

const DlWhiteLink = styled.a`
  display: none;
`

const ProgressBarSection = styled.div`
  flex-grow: 1;
  padding: 0 30px;
`

const ErrorWrapper = styled.div`
  top: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.darkishBlue};
`

const PodcastPlayerSection = styled.section`
  display: none;
  position: fixed;
  z-index: 2030;
  bottom: 0;
  background: #ffffff;
  color: ${({ theme }) => theme.black};
  border-top: solid 1px ${({ theme }) => theme.grey};
  width: 100%;
  box-shadow: 0 -1px 20px 0 rgb(0 0 0 / 15%);
  ${upMd`display: block;`}
  ${({ fullscreen, theme }) =>
    fullscreen &&
    `background: ${theme.persianBlueGradient};
    display: block;
      box-shadow: none;
      color: #ffffff;
      top: 0;
      border-top: none;
      ${PlayerWrapper} {
        height: 100%;
        flex-direction: column;
        width: 300px;
        align-self:center;
      }
      ${Controls} {
        order: 3;
      }
      ${VolumeWrapper} {
        width: 100%;
      }
      ${DlWhiteLink} {
        display: block;
        order: 4;
        align-self: flex-end;
      }
      ${ProgressBarSection} {
        order: 2;
        width: 300px;
        flex-grow: 0;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 10px;
        padding: 0;
      }
      ${ErrorWrapper} {
        top: unset;
        bottom: 0;
        height: auto;
      }
  `}
`

const PodcastCoverImage = styled(GatsbyImage)`
  width: 300px;
  height: 300px;
`

const StyledPlayingWhite = styled(PlayingWhite)`
  width: 200px;
  height: 200px;
  padding: 30px;
`

const StyledPlayingWhiteAnimated = styled(PlayingWhiteAnimated)`
  width: 200px;
  height: 200px;
  padding: 30px;
`

const Button = styled(IconButton)`
  width: 32px;
  height: 32px;
  margin: 0 5px;
  cursor: pointer;
  &:hover {
    background: transparent;
  }
  &:disabled {
    opacity: 0.4;
  }
`

const BigButton = styled(IconButton)`
  width: 62px;
  height: 62px;
  margin: 0 5px;
  cursor: pointer;
  &:hover {
    background: transparent;
  }
`

const MediaTitle = styled(Marquee)`
  font-weight: 500;
  font-size: 24px;
  ${upMd`font-stretch: condensed; font-weight: bold;`}
`

const ErrorMessage = styled.span`
  font-size: 14px;
  flex-grow: 1;
  line-height: normal;
  ${upMd`font-size: 16px;`}
`

const ProgressRingWrapper = styled.div`
  border-radius: 50%;
`

const Volume = styled.div``

const PodcastCover = styled.div`
  width: 300px;
  height: 300px;
`

const PlayerProgressRing = ({
  currentTime,
  duration = 100,
  playing = false,
  size = 64,
  strokeWidth = 4,
  ...props
}) => {
  const theme = useTheme()
  const circleRef = useRef(null)
  const r = size / 2 - strokeWidth / 2
  const circumference = r * 2 * Math.PI
  const ratio = duration > 0 ? currentTime / duration : 0

  useEffect(() => {
    if (circleRef.current) {
      circleRef.current.style.strokeDashoffset =
        circumference - ratio * circumference
    }
  }, [ratio, circumference])

  return (
    <ProgressRingWrapper
      role="progressbar"
      aria-valuemin="0"
      aria-valuemax={duration ? duration : null}
      aria-valuenow={currentTime ? currentTime : null}
      aria-valuetext={
        duration && currentTime
          ? `${durationFormat(currentTime, "humanized")} sur ${durationFormat(
              duration,
              "humanized"
            )}`
          : null
      }
      style={{
        width: size ? size : 64,
        height: size ? size : 64,
      }}
      {...props}
    >
      <svg width={size} height={size}>
        <circle
          fill={theme.darkishBlue}
          r={size / 2}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          stroke={"white"}
          strokeOpacity={0.4}
          strokeWidth={strokeWidth - 2}
          fill="transparent"
          r={r - 2}
          cx={size / 2}
          cy={size / 2}
        />
        <circle
          ref={circleRef}
          className="circle"
          stroke={"white"}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference}
          fill="transparent"
          r={r - 2}
          cx={size / 2}
          cy={size / 2}
          transform={`rotate(-90,${size / 2},${size / 2})`}
        />
        <g
          transform={`translate(${size / 2 - 8.75} ${
            size / 2 - 12.5
          }) scale(0.5 0.5)`}
        >
          <g fill="#fff">
            {playing ? (
              <>
                <rect width="5" height="50" x="20" y="0" rx="2.5">
                  <animate
                    attributeName="height"
                    values="50;40;30;50"
                    dur="1s"
                    repeatCount="indefinite"
                    begin="0s"
                  ></animate>
                  <animate
                    attributeName="y"
                    values="0;5;10;0"
                    dur="1s"
                    repeatCount="indefinite"
                    begin="0s"
                  ></animate>
                </rect>
                <rect width="5" height="30" x="30" y="10" rx="2.5">
                  <animate
                    attributeName="height"
                    values="30;40;20;30"
                    dur="1s"
                    repeatCount="indefinite"
                    begin="0.2s"
                  ></animate>
                  <animate
                    attributeName="y"
                    values="10;5;15;10"
                    dur="1s"
                    repeatCount="indefinite"
                    begin="0.2s"
                  ></animate>
                </rect>
                <rect width="5" height="35" y="8" rx="2.5">
                  <animate
                    attributeName="height"
                    values="35;45;20;35"
                    dur="1s"
                    repeatCount="indefinite"
                    begin="0.4s"
                  ></animate>
                  <animate
                    attributeName="y"
                    values="8;3;15.5;8"
                    dur="1s"
                    repeatCount="indefinite"
                    begin="0.4s"
                  ></animate>
                </rect>
                <rect width="5" height="25" x="10" y="13" rx="2.5">
                  <animate
                    attributeName="height"
                    values="25;35;20;25"
                    dur="1s"
                    repeatCount="indefinite"
                    begin="0.6s"
                  ></animate>
                  <animate
                    attributeName="y"
                    values="13;8;15.5;13"
                    dur="1s"
                    repeatCount="indefinite"
                    begin="0.6s"
                  ></animate>
                </rect>
              </>
            ) : (
              <>
                <rect width="5" height="50" x="20" y="0" rx="2.5" />
                <rect width="5" height="30" x="30" y="10" rx="2.5" />
                <rect width="5" height="35" y="8" rx="2.5" />
                <rect width="5" height="25" x="10" y="13" rx="2.5" />
              </>
            )}
          </g>
        </g>
      </svg>
    </ProgressRingWrapper>
  )
}

const PodcastPlayer = (props) => {
  const [fullscreen, setFullscreen] = useState(false)
  const audio = useRef(null)
  const {
    player: {
      audio: { open, image, indexTrack: index, playlist, playing, error },
    },
    setPlayer,
  } = useMediaPlayer()

  const { playerVolume, volume, mute } = usePlayerVolume({
    color: fullscreen ? "white" : null,
  })
  const {
    playerProgressBar,
    setCurrentTime,
    currentTime,
    setDuration,
    duration,
    setCurrentBuffer,
  } = usePlayerProgressBar({
    onChange: (currentTime) => {
      if (audio.current) {
        audio.current.currentTime = currentTime
      }
    },
    color: fullscreen ? "white" : null,
  })

  const next = useCallback(() => {
    if (index < playlist.length - 1) {
      setPlayer((prevState) => {
        return {
          ...prevState,
          audio: {
            ...prevState.audio,
            indexTrack: index + 1,
            playing: true,
          },
        }
      })
    } else {
      setPlayer((prevState) => {
        return {
          ...prevState,
          audio: {
            ...prevState.audio,
            indexTrack: 0,
            playing: true,
          },
        }
      })
    }
  }, [index, playlist.length, setPlayer])

  useEffect(() => {
    const stop = () => {
      setPlayer((prevState) => {
        return {
          ...prevState,
          audio: {
            ...prevState.audio,
            playing: false,
          },
        }
      })
      setCurrentTime(0)
    }

    const handleLoadedmetadata = () => {
      if (audio.current) {
        setCurrentTime(0)
        setCurrentBuffer(0)
        setDuration(audio.current.duration)
      }
    }

    const handleSeeked = () => {
      setCurrentTime(audio.current.currentTime ? audio.current.currentTime : 0)
    }

    const handleCanplay = () => {
      if (audio.current) {
        audio.current.play()
      }
    }

    const handlePlaying = () => {
      if (playlist && playlist[index]) {
        audioTracking({ audio: playlist[index] })
      }
      setPlayer((prevState) => {
        return {
          ...prevState,
          audio: {
            ...prevState.audio,
            playing: true,
            error: false,
          },
          video: {
            ...prevState.video,
            playing: false,
          },
        }
      })
    }

    const handlePause = () => {
      setPlayer((prevState) => {
        return {
          ...prevState,
          audio: {
            ...prevState.audio,
            playing: false,
          },
        }
      })
    }

    const handleEnded = () => {
      stop()
      if (playlist.length > 1) {
        next()
      }
    }

    const handleLoadingProgress = () => {
      if (audio.current) {
        for (let i = 0; i < audio.current.buffered.length; i++) {
          if (
            audio.current.buffered.start(
              audio.current.buffered.length - 1 - i
            ) < audio.current.currentTime
          ) {
            setCurrentBuffer(
              audio.current.buffered.end(audio.current.buffered.length - 1 - i)
            )
            break
          }
        }
      }
    }

    const handleTimeupdate = () => {
      if (audio.current) {
        setCurrentTime(audio.current.currentTime)
      }
    }

    const handleError = () => {
      setPlayer((prevState) => {
        return {
          ...prevState,
          audio: {
            ...prevState.audio,
            error: true,
          },
        }
      })
    }

    if (playlist && playlist[index]) {
      let currentAudio = audio.current
      if (audio.current) {
        currentAudio.load()
        currentAudio.addEventListener(
          "loadedmetadata",
          handleLoadedmetadata,
          false
        )
        currentAudio.addEventListener("error", handleError, false)
        currentAudio.addEventListener("playing", handlePlaying, false)
        currentAudio.addEventListener("pause", handlePause, false)
        currentAudio.addEventListener("ended", handleEnded, false)
        currentAudio.addEventListener("canplay", handleCanplay, false)
        currentAudio.addEventListener("progress", handleLoadingProgress, false)
        currentAudio.addEventListener("timeupdate", handleTimeupdate, false)
        currentAudio.addEventListener("seeked", handleSeeked, false)
      }
      return () => {
        if (currentAudio) {
          currentAudio.removeEventListener(
            "loadedmetadata",
            handleLoadedmetadata
          )
          currentAudio.removeEventListener("error", handleError)
          currentAudio.removeEventListener("playing", handlePlaying)
          currentAudio.removeEventListener("pause", handlePause)
          currentAudio.removeEventListener("ended", handleEnded)
          currentAudio.removeEventListener("canplay", handleCanplay)
          currentAudio.removeEventListener("progress", handleLoadingProgress)
          currentAudio.removeEventListener("timeupdate", handleTimeupdate)
          currentAudio.removeEventListener("seeked", handleSeeked)
        }
      }
    }
  }, [
    index,
    playlist,
    next,
    setPlayer,
    setCurrentTime,
    setCurrentBuffer,
    setDuration,
  ])

  useEffect(() => {
    if (audio.current) {
      if (playing) {
        if (audio.current.readyState === 4 && audio.current.paused) {
          audio.current.play()
        }
      } else {
        if (!audio.current.paused) {
          audio.current.pause()
        }
      }
    }
  }, [playing])

  useEffect(() => {
    if (audio.current) {
      audio.current.volume = volume
    }
  }, [volume])

  useEffect(() => {
    const handleResize = () => {
      if (fullscreen && window.innerWidth >= 768) {
        setFullscreen(false)
      }
    }
    window.addEventListener("resize", handleResize, false)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  })

  const handleFullscreen = (s) => {
    if (fullscreen || window.innerWidth < 768) {
      setFullscreen(s)
    }
  }

  const play = () => {
    setPlayer((prevState) => {
      return {
        ...prevState,
        audio: {
          ...prevState.audio,
          playing: true,
        },
      }
    })
  }

  const togglePlay = () => {
    setPlayer((prevState) => {
      return {
        ...prevState,
        audio: {
          ...prevState.audio,
          playing: !playing,
        },
      }
    })
  }

  const pause = () => {
    setPlayer((prevState) => {
      return {
        ...prevState,
        audio: {
          ...prevState.audio,
          playing: false,
        },
      }
    })
  }

  const prev = () => {
    if (index > 0) {
      setPlayer((prevState) => {
        return {
          ...prevState,
          audio: {
            ...prevState.audio,
            indexTrack: index - 1,
            playing: true,
          },
        }
      })
    }
  }

  const close = () => {
    setPlayer((prevState) => {
      return {
        ...prevState,
        audio: {
          ...prevState.audio,
          open: false,
          title: "",
          playlist: [],
          indexTrack: 0,
          playing: false,
          error: false,
        },
      }
    })
    setCurrentTime(0)
    setCurrentBuffer(0)
    setDuration(0)
  }

  const rw = () => {
    if (audio.current && currentTime > 10) {
      audio.current.currentTime = currentTime - 10
    }
  }

  const ff = () => {
    if (audio.current && currentTime + 30 < duration) {
      audio.current.currentTime = currentTime + 30
    }
  }

  usePodcastPlayerShortcuts({
    play: togglePlay,
    prev,
    next,
    rw,
    ff,
    mute,
    fullscreen: () => {
      handleFullscreen(!fullscreen)
    },
  })

  if (!open || !playlist || playlist.length === 0) {
    return null
  }

  const audioUrl = playlist[index].entity.fieldMediaAudioFile
    ? playlist[index].entity.fieldMediaAudioFile.entity.remoteUrl
    : playlist[index].entity?.fieldMediaAudioStream?.url?.path

  const previousMediaLabel = playlist[index - 1]
    ? playlist[index - 1].entity.entityLabel
    : null
  const currentMediaLabel = playlist[index].entity.entityLabel
  const nextMediaLabel = playlist[index + 1]
    ? playlist[index + 1].entity.entityLabel
    : null

  const podcastCoverImage = playlist[index].entity.fieldImage
    ? playlist[index].entity.fieldImage.derivative.localFile
    : image

  return (
    <>
      <PodcastPlayerSection
        {...props}
        fullscreen={fullscreen}
        role="dialog"
        aria-label="Lecteur de podcast"
        focusable="true"
        tabIndex="0"
      >
        <Container className={fullscreen ? "h-full" : null}>
          <Row className={fullscreen ? "h-full" : null}>
            <Col
              sm={12}
              className={
                fullscreen
                  ? "h-full flex items-center justify-center py-5"
                  : "flex md:block"
              }
            >
              <PlayerWrapper className="flex items-center justify-start">
                {fullscreen && (
                  <div className="mb-4" style={{ width: 300 }}>
                    <div className="flex items-center justify-between mb-4">
                      <IconButton
                        onPress={() => handleFullscreen(false)}
                        className="self-center"
                        aria-label="Réduire le lecteur"
                      >
                        <ChevronDownWhite
                          aria-hidden="true"
                          focusable="false"
                        />
                      </IconButton>
                      <IconButton
                        onPress={() => {
                          close()
                          setFullscreen(false)
                        }}
                        className="md:hidden"
                        aria-label="Fermer le lecteur"
                      >
                        <CloseWhite aria-hidden="true" focusable="false" />
                      </IconButton>
                    </div>
                    {podcastCoverImage ? (
                      <PodcastCover className="relative">
                        <PodcastCoverImage
                          image={podcastCoverImage.childImageSharp.image}
                          aria-hidden="true"
                          focusable="false"
                          className="absolute"
                          alt={currentMediaLabel}
                        />
                      </PodcastCover>
                    ) : (
                      <div className="flex w-full items-center justify-center">
                        {playing ? (
                          <StyledPlayingWhiteAnimated
                            aria-hidden="true"
                            focusable="false"
                          />
                        ) : (
                          <StyledPlayingWhite
                            aria-hidden="true"
                            focusable="false"
                          />
                        )}
                      </div>
                    )}
                  </div>
                )}

                <Controls className="flex items-center">
                  {playlist.length > 1 && (
                    <Button
                      onPress={() => prev()}
                      disabled={index === 0}
                      aria-controls="podcast-player-audio"
                      aria-label={
                        previousMediaLabel &&
                        `Podcast précédent - ${previousMediaLabel} (SHIFT+p)`
                      }
                    >
                      {fullscreen ? (
                        <PrevWhite aria-hidden="true" focusable="false" />
                      ) : (
                        <Prev aria-hidden="true" focusable="false" />
                      )}
                    </Button>
                  )}
                  {playing ? (
                    <BigButton
                      onPress={() => pause()}
                      disabled={duration === 0}
                      aria-controls="podcast-player-audio"
                      aria-label="Pause (k)"
                    >
                      {fullscreen ? (
                        <PauseWhite aria-hidden="true" focusable="false" />
                      ) : (
                        <Pause aria-hidden="true" focusable="false" />
                      )}
                    </BigButton>
                  ) : (
                    <BigButton
                      onPress={() => play()}
                      disabled={duration === 0}
                      aria-controls="podcast-player-audio"
                      aria-label="Lecture (k)"
                    >
                      {fullscreen ? (
                        <PlayWhite aria-hidden="true" focusable="false" />
                      ) : (
                        <Play aria-hidden="true" focusable="false" />
                      )}
                    </BigButton>
                  )}
                  {playlist.length > 1 && (
                    <Button
                      onPress={() => next()}
                      disabled={index === playlist.length - 1}
                      aria-controls="podcast-player-audio"
                      aria-label={
                        nextMediaLabel &&
                        `Podcast suivant - ${nextMediaLabel} (SHIFT+n)`
                      }
                    >
                      {fullscreen ? (
                        <NextWhite aria-hidden="true" focusable="false" />
                      ) : (
                        <Next aria-hidden="true" focusable="false" />
                      )}
                    </Button>
                  )}
                </Controls>
                <div className="w-full">
                  <MediaTitle
                    text={currentMediaLabel}
                    margin={20}
                    className="md:px-6 mb-3"
                  />
                  <ProgressBarSection className="flex items-center">
                    {playerProgressBar}
                    <a
                      href={audioUrl}
                      target="_blank"
                      className="hidden md:block ml-4"
                      download
                      rel="noreferrer"
                      aria-label="Télécharger le podcast"
                    >
                      <Dl aria-hidden="true" focusable="false" />
                    </a>
                  </ProgressBarSection>
                </div>
                <VolumeWrapper className="flex items-center justify-between order-4">
                  <Volume className="md:pr-10">{playerVolume}</Volume>
                  <IconButton
                    className="hidden md:block"
                    onPress={close}
                    aria-label="Fermer le lecteur"
                  >
                    <Close aria-hidden="true" focusable="false" />
                  </IconButton>
                  <DlWhiteLink
                    href={audioUrl}
                    target="_blank"
                    download
                    aria-label="Télécharger le podcast"
                  >
                    <DlWhite aria-hidden="true" focusable="false" />
                  </DlWhiteLink>
                </VolumeWrapper>
              </PlayerWrapper>
              {/*eslint-disable-next-line jsx-a11y/media-has-caption*/}
              <audio
                id="podcast-player-audio"
                src={audioUrl}
                preload="auto"
                ref={audio}
                title={currentMediaLabel}
                aria-label={currentMediaLabel}
              />
            </Col>
          </Row>
        </Container>
        {error && (
          <ErrorWrapper
            className="absolute text-white"
            role="alert"
            aria-describedby="podcast-player-alert-text"
          >
            <Container className="h-full">
              <Row className="h-full">
                <Col className="flex items-center">
                  <DangerWhite aria-hidden="true" focusable="false" />
                  <ErrorMessage className="px-3" id="podcast-player-alert-text">
                    Une erreur est survenue lors de la lecture du podcast !
                    Veuillez essayer ultérieurement.
                  </ErrorMessage>
                  <IconButton aria-label="Fermer le lecteur" onPress={close}>
                    <CloseWhite aria-hidden="true" focusable="false" />
                  </IconButton>
                </Col>
              </Row>
            </Container>
          </ErrorWrapper>
        )}
      </PodcastPlayerSection>
      <PodcastPlayerWidget
        className="md:hidden"
        onPress={() => handleFullscreen(true)}
        aria-label="Ouvrir le lecteur de podcast"
      >
        <PlayerProgressRing
          currentTime={currentTime}
          duration={duration}
          playing={playing}
          aria-label={`Barre de progression - Lecture du podcast ${currentMediaLabel}`}
        />
      </PodcastPlayerWidget>
    </>
  )
}

export default PodcastPlayer
