/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// Import the taiwind CSS file
import "./src/styles/global.css"

// IntersectionObserver polyfill for gatsby-image (Safari, IE)
export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
  if (typeof fetch === `undefined`) {
    await import(`whatwg-fetch`)
  }
}

import React from "react"
import LayoutWrapper from "./src/components/Layout/LayoutWrapper"
export const wrapPageElement = ({ element, props }) => {
  // props provide same data to Layout as Page element will get
  // including location, data, etc - you don't need to pass it
  return <LayoutWrapper {...props}>{element}</LayoutWrapper>
}

/*const parsePathname = (pathname) => pathname.match(/[^/?]*[^/?]/g)

const isSameCategory = (a, b) => {
  return parsePathname(a)[1] === parsePathname(b)[1]
}
*/

export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
}) => {
  if (!prevRouterProps) {
    return true
  }

  if (location.pathname === prevRouterProps.location.pathname) {
    return false
  }

  // Keep the scroll at the same level, only if the user stays on the same category
  //return !isSameCategory(location.pathname, prevRouterProps.location.pathname)
}
