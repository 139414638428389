import asciiFolder from "fold-to-ascii"

const normalize = (str) => {
  return asciiFolder.foldReplacing(str).toLocaleLowerCase()
}

export const audioTracking = ({ audio }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "va-gtm-events",
    eventCategory: "audio",
    eventAction: normalize(audio.entity.entityLabel),
    eventLabel: window.location.href,
  })
}

export const fileDownloadTracking = ({ file }) => {
  if (file.eventTracking) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "va-gtm-events",
      eventCategory: "pdf",
      eventAction: normalize(file.name),
      eventLabel: "",
    })
  }
}

export const faqTracking = ({ faq }) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "va-gtm-events",
    eventCategory: "faq",
    eventAction: normalize(faq.title),
    eventLabel: "",
  })
}

const fillOffer = (offer) => {
  let category = offer.type.targetId === "individuals" ? "btoc" : "btob"
  let price = offer.prices && offer.prices[0].entity
  price = price ? price.entityLabel.replace(/€|HT| /g, "") : "indisponible"
  price = price ? price.replace(/,/, ".") : "indisponible"
  let orderUrl = offer.orderUrl ? offer.orderUrl.url.path : false
  let sku
  if (orderUrl) {
    let match = orderUrl.match(/(cod_crcl|code)=([^&]*)/)
    if (match) {
      sku = match[2]
    }
  }
  return {
    name: offer.trackingProductName
      ? offer.trackingProductName
      : offer.entityLabel, // nom unique du produit. Exemple "TEMPS LIBRE"
    id: sku ? sku : "indisponible", // sku unique du produit. Exemple "EVA3"
    price: price, // montant mensuel des frais de gestion (temps libre : 2,...)
    brand: "ulys",
    category: category,
    variant: "", // laisser vide
  }
}

export const catalogTracking = (offers) => {
  let offerArray = offers.map(({ entity }, index) => ({
    ...fillOffer(entity),
    position: index + 1,
  }))

  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "va-gtm-events",
    eventCategory: "ecommerce",
    eventAction: "product impressions",
    eventLabel: "",
    nonInteraction: true,
    isConversion: false,
    ecommerce: {
      currencyCode: "EUR",
      impressions: offerArray,
    },
  })
}

export const orderButtonTracking = (offer) => {
  let offerData = fillOffer(offer)
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "va-gtm-events",
    eventCategory: "ecommerce",
    eventAction: "add to cart",
    eventLabel: offerData.id, // ID unique de la formule (EVA3, EVA1 etc..)
    ecommerce: {
      currencyCode: "EUR", // EUR
      add: [
        {
          // la longueur du tableau est égale au nombre de produit affiché
          name: offerData.trackingProductName
            ? offerData.trackingProductName
            : offerData.entityLabel, // nom du produit du tunnel. Exemple : "Temps libre"
          id: offerData.id, // ID du produit du tunnel. Exemple : "EVA3"
          price: offerData.price, // montant des frais de gestion mensuels lorsque
          brand: "ulys", // marque ulys
          category: offerData.category,
          variant: "", // options sélectionnées (exemple : "premium:oui-europe:non")
          quantity: 1, // nombre de badge sélectionné (sinon 0 lorsque inconnu)
        },
      ],
    },
  })
}

export const moreButtonTracking = (offer) => {
  let offerData = fillOffer(offer)
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "va-gtm-events",
    eventCategory: "links tracking",
    eventAction: "en savoir plus",
    eventLabel: offerData.id, // ID unique de la formule (EVA3, EVA1 etc..)
    nonInteraction: false,
  })
}

export const pageTracking = (page) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "gatsby-route-change-custom",
    pageName: page.name,
    pageChapter: page.chapter, // Exemple : La fondation, Nos actions, Nos partenaires etc.
    pageTemplate: page.template, // Exemple : Article ou Presse
    pageCategory: page.category, // Exemple : Conduites responsables, Études et enquêtes etc.
    pageTheme: page.theme, // Exemple : Études et enquêtes, Actions de sensibilisation, Campagnes
  })
}

export const pressDownloadTracking = (href) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "va-gtm-events",
    eventCategory: "Downloads",
    eventAction: "PDF",
    eventLabel: href,
  })
}

export const fundRequestTracking = (type) => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "va-gtm-events",
    eventCategory: "Form",
    eventAction: "Send",
    eventLabel:
      type === "associations" ? "Dossier_associations" : "Dossier_chercheurs",
  })
}

export const contactTracking = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: "va-gtm-events",
    eventCategory: "Form",
    eventAction: "Send",
    eventLabel: "Contact",
  })
}
